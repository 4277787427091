<template>
  <div>
    <b-card>
      <div
        v-if="isLoading === true"
        class="text-center mb-2"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
      </div>
      <b-form-group
        label="Livreur"
      >
        <validation-provider
          #default="{ errors }"
          name="Livreur"
        >
          <v-select
            v-model="payment.delivery_man"
            :clearable="false"
            label="username"
            placeholder="Livreur"
            disabled
            :options="delivery_mans"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label="Référence de colis"
        label-for="vi-first-name"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="MaximizeIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="ref"
            placeholder="Scanner un colis"
            @change="checkOrder"
          />
        </b-input-group>
      </b-form-group>
    </b-card>

    <!-- table -->
    <div class="d-flex justify-content-between">

      <b-card>
        <h4>
          colis livré <!-- ({{ delivredOrders.length }}) -->
        </h4>
        <vue-good-table
          :columns="columns"
          :rows="delivredOrders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="CornerDownRightIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  color="red"
                  @click="addItem(props.row)"
                />
                <b-tooltip
                  title="Retour"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-card class="ml-1">
        <h4>Colis retour ({{ returnOrders.length }})</h4>
        <vue-good-table
          :columns="columns"
          :rows="returnOrders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="DeleteIcon"
                  color="red"
                  class="cursor-pointer mr-1"
                  size="16"
                  @click="resetItem(props.row)"
                />
                <b-tooltip
                  title="Annuler"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>

        </vue-good-table>

      </b-card>

    </div>
    <b-card
      no-body
      class="card-developer-meetup"
    >
      <div class="bg-light-primary rounded-top text-center">
        <b-img
          :src="require('@/assets/images/email.svg')"
          alt="Meeting Pic"
          height="170"
        />
      </div>
      <b-card-body>
        <!-- metting header -->
        <div class="meetup-header d-flex align-items-center">

          <div class="my-auto">
            <b-card-title class="mb-25">
              Paiement de colis
            </b-card-title>
            <b-card-text class="mb-0">
              Total de tous les colis: <b-badge
                pill
                variant="primary"
                class="badge-glow"
              >
                {{ all }}
              </b-badge>
            </b-card-text>
          </div>
        </div>
        <!--/ metting header -->

        <!-- media -->
        <div class="d-flex flex-row align-items-center">
          <b-media
            class="mt-1"
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="FolderPlusIcon"
                  size="18"
                  color="green"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="d-flex mt-1">
              <h6 class="mb-0">
                Nombre de colis livré
              </h6>
              <div class="ml-2">
                <feather-icon
                  icon="BellIcon"
                  size="25"
                  class="text-success "
                  :badge="delivredOrders.length"
                  badge-classes="badge-success badge-glow"
                />
              </div>

            </b-media-body>
          </b-media>
          <b-media
            class="ml-5"
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                  color="green"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="d-flex mt-1">
              <h6 class="mb-0">
                Total de colis livré
              </h6>
              <div class="ml-2">
                <b-badge
                  pill
                  variant="success"
                  class="badge-glow"
                >
                  {{ priceDelivredOrder }}
                </b-badge>
              </div>

            </b-media-body>
          </b-media>

          <b-media
            class="ml-5"
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                  color="green"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body
              v-if="user.role ==='admin'"
              class="d-flex mt-1"
            >
              <h6 class="mb-0">
                Total de frais de livraison
              </h6>
              <div class="ml-2">
                <b-badge
                  pill
                  variant="success"
                  class="badge-glow"
                >
                  {{ totalDeliveryPrice }}
                </b-badge>
              </div>

            </b-media-body>
          </b-media>

        </div>
        <div class="d-flex">
          <b-media
            class="mt-1"
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="FolderMinusIcon"
                  size="18"
                  color="red"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="d-flex mt-1">
              <h6 class="mb-0">
                Nombre de colis retour
              </h6>
              <div class="ml-1">
                <feather-icon
                  icon="BellIcon"
                  size="25"
                  class="text-danger "
                  :badge="returnOrders.length"
                  badge-classes="badge-danger badge-glow"
                />
              </div>

            </b-media-body>
          </b-media>
          <b-media
            class="ml-4"
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="18"
                  color="red"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="d-flex mt-1">
              <h6 class="mb-0">
                Total de colis retour
              </h6>
              <div class="ml-1">
                <b-badge
                  pill
                  variant="danger"
                  class="badge-glow"
                >
                  {{ priceReturnOrder }}
                </b-badge>

              </div>

            </b-media-body>
          </b-media>
        </div>

        <b-form-group class=" mt-2">
          <b-button
            variant="gradient-primary"
            class="ml-1 d-inline-block"
            :class="{ 'disabled-cursor': isLoading }"
            :disabled="isLoading"
            @click="validate"
          >
            <feather-icon icon="CheckIcon" />
            <span class="ml-1">Modifier Paiement</span>
          </b-button>
        </b-form-group>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip,
  BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, VBTooltip, BMediaBody, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BImg,
    BBadge,
    BCardBody,
    BCardText,
    BMedia,
    BMediaAside,
    BAvatar,
    BTooltip,
    BMediaBody,
    BButton,
    ValidationProvider,
    VueGoodTable,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    vSelect,
    BCard,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      all: 0,
      delivery_mans: [],
      delivery_man: '',
      status: '',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'BL',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'BL',
          },
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche client',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'reherche gouvernorat',
          },
        },
        {
          label: 'Delegation',
          field: 'delegation',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche delegation',
          },
        },
        {
          label: 'Telephone',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'reherche telephone',
          },
        },
        {
          label: 'prix',
          field: 'price_ttc',
          filterOptions: {
            enabled: true,
            placeholder: 'recherche prix',
          },
        },
      ],
      role: '',
      delivredOrders: [],
      returnOrders: [],
      roadmap: {},
      searchTerm: '',
      ref: '',
      roadmaps: [],
      payment: [],
      user: {},
      isLoading: false,
    }
  },
  computed: {
    priceDelivredOrder() {
      return this.delivredOrders.reduce((accumulator, item) => accumulator + Number(item.price_ttc), 0)
    },
    priceReturnOrder() {
      return this.returnOrders.reduce((accumulator, item) => accumulator + Number(item.price_ttc), 0)
    },
    totalDeliveryPrice() {
      return this.delivredOrders.reduce((accumulator, item) => accumulator + Number(item.price_delivery), 0)
    },
  },
  created() {
    this.getDeliveryMans()
    this.getPayment()
    this.user = storeAuth.state.user
  },
  methods: {
    async getPayment() {
      this.isLoading = true
      await axios.get(`/api/payments/detail/${this.$route.params.id}`).then(response => {
        this.payment = response.data
        this.delivredOrders = this.payment.delivred_orders
        this.returnOrders = this.payment.return_orders
        this.delivredPayedOrders = this.payment.delivred_orders.filter(order => order.status === 6)
        this.returnPayedOrders = this.payment.return_orders.filter(order => order.status === 9)
        this.all = this.payment.delivred_price + this.payment.return_price
      })
      this.isLoading = false
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    checkOrder() {
      const result = this.delivredOrders.find(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === Number(this.ref) || element.id === Number(this.ref)
        }
        return element.id === Number(this.ref)
      })
      if (result) {
        this.addItem(result).then(() => { this.ref = '' })
      } else {
        this.withTitle('Colis non exist')
      }
    },
    async validate() {
      const obj = {
        id: this.payment.id,
        returnOrders: this.returnOrders,
        delivredOrders: this.delivredOrders,
        delivredPayedOrders: this.delivredPayedOrders,
        returnPayedOrders: this.returnPayedOrders,
        priceDelivredOrder: this.priceDelivredOrder,
        priceReturnOrder: this.priceReturnOrder,
        totalDeliveryPrice: this.totalDeliveryPrice,
        repository: storeAuth.state.user.repository,
        delivery_man: this.payment.delivery_man.id,
        user: storeAuth.state.user,
      }
      try {
        this.isLoading = true
        const response = await axios.post('/api/payments/update-payment/', obj)
        this.$router.push(`/payments/show/${response.data}`)
        this.showToast('success', 'top-center', 'Paiement effectuée avec succés')
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    async getDeliveryMans() {
      this.isLoading = true
      await axios.get('api/auth/users/', {
        params: {
          role: 'delivery_man',
          repository: storeAuth.state.user.repository,
        },
      }).then(response => {
        this.delivery_mans = response.data
        this.isLoading = false
      })
    },
    async addItem(item) {
      this.returnOrders.push(item)
      const i = this.delivredOrders.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })
      this.delivredOrders.splice(i, 1)
    },

    async resetItem(item) {
      this.delivredOrders.push(item)
      const i = this.returnOrders.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })
      this.returnOrders.splice(i, 1)
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
